import React from "react";
import classes from './RunningBanner.module.css'

export const RunningBanner = ({children}) => {
    return(
        <div className={classes.line_place}>
            <div className={classes.running_line}>
                <h1>{children}</h1>
                <h1>{children}</h1>
                <h1>{children}</h1>
            </div>
        </div>
    )
}