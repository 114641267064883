import React from "react";
import classes from './RegFooterBut.module.css'
import RoundVector from "../RoundVector/RoundVector";

export const RegFooterBut = ({children}) => {
    return(
        <div className={classes.footer_but}>
            <div className={classes.text_part}>{children}</div>
            <RoundVector/>
        </div>
    )
}