import React from "react";

function Header({incartProdCount}){
    return(
        <header>
            <div className="but-part">
                <div className="menu-burger-icon"></div>
                <div className="search-icon"></div>
            </div>
            <div className="logo-part"><h1>KRAKEN</h1></div>
            <div className="but-part">
                <div className="cart-icon">
                    <div className="number">{5}</div>
                    <div className="icon"></div>
                </div>
                <div className="profile-icon"></div>
            </div>
        </header>
    )
}

export default Header