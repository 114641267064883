import axios from 'axios'
const SRVRADDRESS = 'https://kamazaz.store:443/users/'

export const axiosAsk = (type, payload) => {
    if(type === 'post'){
        return axios.post(SRVRADDRESS, payload)
    }else if(type === 'get'){
        return axios.get(SRVRADDRESS, payload)
    }else{
        console.error('wrong type of axios request')
    }
}