import React, { useEffect, useState } from "react";
import classes from './ProdsTypeCard.module.css'

export default function ProdsTypeCard({item, id}){
    const[count, setCount] = useState('0 товаров');

    useEffect(() => {
        if(item?.count < 100){
            setCount('менее 100')
        }else if(item?.count >= 100 && item?.count <= 200){
            setCount('более 100')
        }else if(item?.count > 200){
            setCount(`${item.count} товаров`)
        }
    }, [item.count])

    return(
        <div className={classes.card_place}>
            <div id={id} className={classes.card}>
                <div>
                    <div className={classes.image}>
                        <img src={item?.src}/>
                    </div>
                    <div className={classes.info}>
                        <div className={classes.vector_icon}></div>
                        <div className={classes.text_part}>
                            <div className={classes.text_header}>{item?.name}</div>
                            <div className={classes.main_text}>{count}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}