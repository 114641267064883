import React from "react";
import classes from './FeaturesCard.module.css'

export const FeaturesCardText = ({children}) => {
    return(
        <div className={classes.info_card}>
            <div className={classes.info_icon}></div>
            <div className={classes.info_text}>{children}</div>
        </div>
    )
}

export const FeaturesCardImg = ({line1, line2, src, index}) => {
    if(index%2 == 0){
        return(
            <div className={classes.features_card}>
                <div className={classes.image}><img src={src}/></div>
                <div className={classes.text_part}>
                    <div className={classes.text_black}>{line1}</div>
                    <div className={classes.text_purple}>{line2}</div>
                </div>
            </div>
        )
    }else{
        return(
            <div className={classes.features_card}>
                <div className={classes.image}><img src={src}/></div>
                <div className={classes.text_part}>
                    <div className={classes.text_purple}>{line1}</div>
                    <div className={classes.text_black}>{line2}</div>
                </div>
            </div>
        )
    }
}