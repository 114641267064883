import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './css/General.css'

import Header from './components/Parts/Main/Header';
import Footer from './components/Parts/Main/Footer';
import MainPage from './components/Pages/MainPage';

function App() {
  return(
    <BrowserRouter>
      <Header/>
      <main>
        <Routes>
          <Route path='' element={<MainPage/>}/>
        </Routes>
      </main>
      <Footer/>
    </BrowserRouter>
  )
}

export default App;
