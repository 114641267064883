import React from "react";
import { useNavigate } from "react-router-dom";
import '../../../css/Cards.css';
import RoundVector from "../../UI/RoundVector/RoundVector";

export function BrandCard({item}){
    const navigate = useNavigate();

    return(
        <div className="brand-card">
            <div className="image">
                <img src={item?.src}/>
            </div>
            <div className="name-text">{item?.brand}</div>
            <div className='price-text'>{`от ${item?.price} ₽`}</div>
            <div className="link-part">
                <RoundVector/>
                <div className="link-text-part">
                    <div>{`${item?.model_count} моделей`}</div>
                    <div>{`${item?.tov_count} товаров`}</div>
                </div>
            </div>
        </div>
    )
}

export function FinalBrandCard(){
    return(
        <div className="brand-card final">
            <div className="other-part">
                <div className="other-text">Дрегие бренды</div>
                <div className="other-vector"></div>
            </div>
            <div className="other-image"></div>
        </div>
    )
}

export function ProdCard({item}){
    return(
        <div className="prod-card">
            <div>
                <div className="like-but"></div>
                <div className="prod-card-gallery">
                    <img src={item?.src}/>
                </div>
                <div className="slide-counter"></div>
                <div className="prod-card-name">{item?.name}</div>
                <div className="card-money-part">
                    <div className="prod-card-price">{item?.cost}</div>
                    <div className='card-cr-coin-icon'>+ 5 000</div>
                </div>
            </div>
        </div>
    )
}