import React from "react";
import { ProdCard } from "./StackItems/Cards";

export function CatalogHorizontal(rowCount){
    return(
        <div>
            <ProdCard/>
        </div>
    )
}
export function CatalogVertical(){

}