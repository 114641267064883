import React, { useState } from "react";
import { RegFooterBut } from "../../UI/RegFooterBut/RegFooterBut";

function Footer(){
    const[unregUser, setUnregUser] = useState(false);

    return(
        <footer>
            {unregUser
                ?<div className="footer-top-part">
                    <img src="./img/png/shop-button.png"/>
                    <div className="screaming-text-place">
                        <h1>ЗАРЕГИСТРИРУЙТЕСЬ ПРЯМО СЕЙЧАС</h1>
                        <p>И <span>получите 500 ₽ на первый заказ,</span> а так же разблокируйте избранное, индивидуальный подбор товара, и кешбэк программу</p>
                        <RegFooterBut>ЗАРЕГИСТРИРОВАТЬСЯ</RegFooterBut>
                    </div>
                </div>
                :<></>
            }
            <div className="footer-bottom-part">
                <div className="footer-main-info">
                    <h1>KRAKEN</h1>
                    <div>Все материалы защищены авторским правом, 2024</div>
                </div>
                <div className="footer-sections">
                    <div className="footer-sections-column">
                        <div>Бренды</div>
                        <div>ТОП-обедь</div>
                        <div>Вся обувь</div>
                        <div>Одежда</div>
                    </div>
                    <div className="footer-sections-column">
                        <div>Сумки</div>
                        <div>Аксессуары</div>
                        <div>Часы</div>
                        <div>Искусство</div>
                    </div>
                    <div className="footer-sections-column">
                        <div>Политика конфиденциальности</div>
                        <div>Условия возврата</div>
                        <div>Условия оплаты</div>
                        <div>Контакты</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;